(function() {
	jQuery(function($) {

		$("#search-input").on('keyup', function() {
			if(this.value) {
				$.ajax({
						url: '/search', //Defined in your routes file
						method: 'post',
						beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
						data: (
								'query=' + this.value
						),
						success: function(data, textStatus, xhr) {
							if (data) {
								console.log(data["value"]);
								$("#search-results").html(data["value"]);
							} else {
								$("#search-results").html("<li>Pas de résultat</li>");
							}
						},
						error: function() {
							console.log("Ajax error!");
							$("#search-results").html("");
						}
				})
			} else {
				$("#search-results").html("");
			}	
		});
	
	}); 
})();