jQuery(function() {
  if ($('#infinite-scrolling').length > 0) {
    return $(window).on('scroll', function() {
			var more_posts_url = $('.pagination .next_page').attr('href');
      if (more_posts_url && $(window).scrollTop() > $(document).height() - $(window).height() - 300) {
        $('.pagination').html('<img src="/assets/ajax-loader.gif" alt="Chargement..." title="Chargement..." />');
				$.getScript(more_posts_url);
      }
      return;
    });
  }
});